<template>
  <component :is="form === undefined ? 'div' : 'b-card'">
    <template>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <!-- media -->
              <b-media no-body>
                <b-media-body class="mt-75 ml-75">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <!-- upload button -->

                      <image-cropper
                        v-model="fileForm"
                        label="manager image"
                        :stencil-props="{ aspectRatio: 1280/720, checkImageOrigin: false }"
                        :url.sync="form.thumbnail"
                      />
                      <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                      <!--/ upload button -->
                    </b-col>
                  </b-row>
                </b-media-body>
              </b-media>
              <!--/ media -->
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <!-- Title -->
            <b-col
              cols="12"
              sm="6"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="title"
                rules="required"
              >
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="form.title"
                    :state="getValidationState(validationContext)"
                    trim
                    autocomplete="off"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Video Link -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="video_link"
                rules="url"
              >
                <b-form-group
                  label="Video Link"
                  label-for="video_link"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="video_link"
                    v-model="form.video_link"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- datepicker -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="published_at"
              >
                <b-form-group
                  label="Published At"
                  label-for="published_at"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-datepicker
                    id="published_at"
                    v-model="form.published_at"
                    :state="getValidationState(validationContext)"
                    locale="en"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Description -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Description"
                rules="required"
              >
                <b-form-group
                  label="description"
                  label-for="description"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-textarea
                    v-model="form.description"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(val) => val.value"
                    input-id="description"
                    rows="4"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Form Actions -->
            <b-col cols="12">
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-2"
                  type="submit"
                >
                  {{ postId ? "update" : "add" }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-danger"
                >
                  reset
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </component>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BFormFile,
    BImg,
    BFormTextarea,
    BCardText,
    BButton,
    BMediaBody,
    BMediaAside,
    BFormInvalidFeedback,
    BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, url } from '@validations'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
// repository
import FileRepository from '@/abstraction/repository/fileRepository'
import PostRepository from '@/abstraction/repository/postRepository'
// random token
import { makeid } from '@/utils/math'
import debouncedFunction from '@/utils/filter/autocompleteFilter.js'
import useUsersList from '../user/useUsersList'
import userStoreModule from '../user/userStoreModule'
import Calendar from "@/views/components/calendar/Calendar";

const postRepository = new PostRepository()
const RANDOM_TOKEN = makeid(50)

export default {
    components: {
      Calendar,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
      BFormDatepicker,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        required,
        url,
        isLoading: false,
    }),
    setup() {
        const blankForm = {
            title: '',
            description: '',
            video_link: '',
            published_at: '',
            file_batch_id: RANDOM_TOKEN,
            thumbnail: 'https://via.placeholder.com/1280x720',
        }
        const postId = ref(router.currentRoute.params.id)
        const fileForm = ref({
            batch_id: RANDOM_TOKEN,
            collection_name: 'main_image',
            model_name: 'post',
            crop: {},
        })
        const roleOptions = [
            { label: 'Admin', value: 'admin' },
            { label: 'Author', value: 'author' },
            { label: 'Editor', value: 'editor' },
            { label: 'Maintainer', value: 'maintainer' },
            { label: 'Subscriber', value: 'subscriber' },
        ]

        const planOptions = [
            { label: 'Basic', value: 'basic' },
            { label: 'Company', value: 'company' },
            { label: 'Enterprise', value: 'enterprise' },
            { label: 'Team', value: 'team' },
        ]
        const form = ref(blankForm)

        const USER_APP_STORE_MODULE_NAME = 'app-user'

        const { refetchData } = useUsersList()
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

        // UnRegister on leave
        onUnmounted(async () => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        onMounted(async () => {
            if (postId.value) {
                const resource = await postRepository.show(postId.value)
                form.value = resource
                form.value.file_batch_id = RANDOM_TOKEN
            }
        })
        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankForm))
        }
        const onSubmit = async () => {
            try {
                const fileRepository = new FileRepository()
                if (router.currentRoute.params.id) {
                    if (fileForm.value.file) {
                        await fileRepository.store(fileForm.value)
                    }
                    await postRepository.update(form.value.id, form.value)
                    router.push({ name: 'apps-post-list' })
                } else {
                    await fileRepository.store(fileForm.value)
                    await postRepository.store(form.value)
                    router.push({ name: 'apps-post-list' })
                }
            } catch (e) {
                console.log(e)
                return e
            }
        }
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform)
        return {
            fileForm,
            form,
            refetchData,
            onSubmit,
            postId,
            refFormObserver,
            getValidationState,
            resetForm,
            avatarText,
            roleOptions,
            planOptions,
        }
    },
    watch: {
        '$route.params': function (to, from) {
            this.postId = to.id
        },
    },
    methods: {

    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
